import { asyncActionType, createActionTypes } from '@gupy/front-commons';

export default createActionTypes(
  'Skill',
  asyncActionType('POST'),
  asyncActionType('PUT'),
  asyncActionType('DELETE'),
  asyncActionType('GET_ALL'),
  asyncActionType('SEARCH'),
);
