import { GoogleAnalyticsHelper } from '@gupy/front-helpers';
import PropTypes from 'prop-types';
import React from 'react';
import getKnowMoreLinkForPlan from '../../constants/CompaniesPlansToKnowMoreLinks';
import { ButtonLink } from './ButtonLink';
import * as HigherPlanWarningStyles from './HigherPlanWarning.styles';

const propTypes = {
  title: PropTypes.string,
  description: PropTypes.string.isRequired,
  buttonMessage: PropTypes.string,
  buttonUrl: PropTypes.string,
  currentUser: PropTypes.object.isRequired,
  noMargin: PropTypes.bool,
  subDescription: PropTypes.string,
  gaProps: PropTypes.shape({
    category: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
};

const HigherPlanWarning = ({
  title,
  description,
  buttonMessage,
  buttonUrl,
  currentUser,
  noMargin,
  subDescription,
  gaProps = undefined,
}) => {
  const showUpgradePlanButton =
    currentUser && ['root', 'owner'].includes(currentUser.userType);
  let companyPlan = '';
  if (currentUser && currentUser.products) {
    const companyProduct = currentUser.products.find(
      product => product.plan !== '',
    );
    if (companyProduct) {
      companyPlan = companyProduct.plan;
    }
  }

  const knowMoreLink = buttonUrl || getKnowMoreLinkForPlan(companyPlan);

  const onClickKnowMoreLink = link => {
    if (gaProps) {
      GoogleAnalyticsHelper.sendEvent({
        category: gaProps.category,
        action: gaProps.action,
        label: gaProps.label,
      });
    }
    window.open(link, '_blank');
  };

  return (
    <HigherPlanWarningStyles.Tip
      className="higher-plan-warning"
      inverted
      type="hint"
      noIcon
      noMargin={noMargin}
    >
      <HigherPlanWarningStyles.Content>
        <HigherPlanWarningStyles.Logo
          className="higher-plan-warning-logo"
          src={`${process.env.REACT_APP_ASSETS_URL}/repacking/higher-plan-logo.svg`}
          alt=""
          role="presentation"
        />
        <div>
          {title && (
            <HigherPlanWarningStyles.Title>
              {title}
            </HigherPlanWarningStyles.Title>
          )}
          <HigherPlanWarningStyles.Paragraph>
            {description}
          </HigherPlanWarningStyles.Paragraph>
          {subDescription && (
            <HigherPlanWarningStyles.SubDescription>
              {subDescription}
            </HigherPlanWarningStyles.SubDescription>
          )}
        </div>
        {buttonMessage && showUpgradePlanButton && (
          <ButtonLink
            onClick={() => onClickKnowMoreLink(knowMoreLink)}
            href="#"
          >
            {buttonMessage}
          </ButtonLink>
        )}
      </HigherPlanWarningStyles.Content>
    </HigherPlanWarningStyles.Tip>
  );
};

HigherPlanWarning.propTypes = propTypes;

export default HigherPlanWarning;
