import moment from 'moment';
import { combineReducers } from 'redux';
import { JobPictureActionTypes } from '../../actions/Job/JobPictures/JobPicturesAction';
import JobActionTypes from '../../constants/Job/JobActionTypes';
import JobDetailsActionTypes from '../../constants/Job/JobDetailsActionTypes';
import JobInternalDataActionTypes from '../../constants/Job/JobInternalDataActionTypes';
import JobRatingCriteriasActionTypes from '../../constants/Job/JobRatingCriterias/JobRatingCriteriasActionTypes';
import jobStatusEnum from '../../constants/Job/jobStatusEnum';
import JobWorkplaceActionTypes from '../../constants/Job/JobWorkplaceActionTypes';

const initialModelForm = {
  id: undefined,
  status: undefined,
  name: undefined,
  jobBoards: [],
  permissions: undefined,
  publicationType: undefined,
  quickApply: false,
};

const initialJobList = {
  data: [],
  summary: {
    page: 0,
    perPage: 0,
    pagetCount: 0,
    recordsTotal: 0,
    parameters: {},
    order: [],
  },
};

const formatDatabaseDate = date => {
  if (!date || date === '') {
    return null;
  }

  return moment.utc(date).format('DD/MM/YYYY');
};

export const statusList = (state = [], action) => {
  switch (action.type) {
    case JobActionTypes.GET_ALL_SUCCESS:
      return action.statusList;
    default:
      return state;
  }
};

export const statusLabels = (state = {}, action) => {
  switch (action.type) {
    case JobActionTypes.GET_ALL_SUCCESS:
      return action.statusList.reduce((accumulator, obj) => {
        accumulator[obj.value] = obj.label;
        return accumulator;
      }, {});
    default:
      return state;
  }
};

export const jobList = (state = initialJobList, action) => {
  switch (action.type) {
    case JobActionTypes.GET_ALL_SUCCESS: {
      return {
        data: action.jobList.data.map(job => ({
          ...job,
          publishedAtLabel: formatDatabaseDate(job.publishedAt),
          registerEndDateLabel: formatDatabaseDate(job.registerEndDate),
          expiresAtLabel: formatDatabaseDate(job.expiresAt),
        })),
      };
    }
    case JobActionTypes.PIN_SUCCESS:
    case JobActionTypes.UNPIN_SUCCESS: {
      const newData = [...state.data];
      return {
        data: newData.map(job => ({
          ...job,
          publishedAtLabel: formatDatabaseDate(job.publishedAt),
          registerEndDateLabel: formatDatabaseDate(job.registerEndDate),
          expiresAtLabel: formatDatabaseDate(job.expiresAt),
        })),
      };
    }
    default:
      return state;
  }
};

export const careerPagesAreLoading = (state = true, action) => {
  switch (action.type) {
    case JobActionTypes.GET_COMPANY_CAREER_PAGES:
      return true;
    case JobActionTypes.GET_COMPANY_CAREER_PAGES_SUCCESS:
    case JobActionTypes.GET_COMPANY_CAREER_PAGES_FAIL:
      return false;
    default:
      return state;
  }
};

export const isJobAnalysisLoading = (state = false, action) => {
  switch (action.type) {
    case JobActionTypes.GET_JOB_ANALYSIS:
      return true;
    case JobActionTypes.GET_JOB_ANALYSIS_SUCCESS:
    case JobActionTypes.GET_JOB_ANALYSIS_FAIL:
      return false;
    default:
      return state;
  }
};

export const isJobFieldFilledModalOpen = (state = false, action) => {
  switch (action.type) {
    case JobActionTypes.OPEN_JOB_FIELD_FILLED_MODAL:
      return true;
    case JobActionTypes.CLOSE_JOB_FIELD_FILLED_MODAL:
    case JobActionTypes.GET_JOB_ANALYSIS_SUCCESS:
    case JobActionTypes.GET_JOB_ANALYSIS_FAIL:
      return false;
    default:
      return state;
  }
};

export const jobAnalysis = (state = {}, action) => {
  switch (action.type) {
    case JobActionTypes.GET_JOB_ANALYSIS_SUCCESS:
      return action.response.body;
    case JobActionTypes.GET_JOB_ANALYSIS_FAIL:
      return null;
    case JobActionTypes.GET_JOB_ANALYSIS:
    case JobActionTypes.CLEAR_JOB_ANALYSIS:
      return {};
    default:
      return state;
  }
};

const initialSummary = {
  filter: {},
  order: 'expiresAt',
  recruiters: [],
  pagination: {
    page: 0,
    perPage: 50,
    pageCount: 1,
  },
};

export const jobListSummary = (state = initialSummary, action) => {
  switch (action.type) {
    case JobActionTypes.CHANGE_SUMMARY:
    case JobActionTypes.CHANGE_SUMMARY_WITHOUT_REQUEST:
      return action.summary;
    case JobActionTypes.CLEAR_SUMMARY:
      return initialSummary;
    case JobActionTypes.GET_ALL_SUCCESS: {
      const statusFilter = action.jobList.summary.parameters.status;
      const filter = statusFilter ? { status: statusFilter } : {};
      filter.search =
        state.filter && state.filter.search ? state.filter.search : '';
      filter.recruiterIds = action.jobList.summary.parameters.recruiterIds;

      return {
        ...state,
        filter,
        recruiters: action.recruiters,
        pagination: {
          page: action.jobList.summary.page,
          perPage: action.jobList.summary.perPage,
          pageCount: action.jobList.summary.pageCount,
        },
      };
    }
    default:
      return state;
  }
};

export const model = (state = initialModelForm, action) => {
  switch (action.type) {
    case JobActionTypes.GET_SUCCESS: {
      const newState = {
        id: action.model.id,
        status: action.model.status,
        name: action.model.name,
        jobBoards: action.model.jobBoards,
        permissions: action.model.permissions,
        careerPage: action.model.careerPage,
        publicationType: action.model.publicationType,
        jobType: action.model.jobType,
        createdAt: action.model.createdAt,
        quickApply: action.model.quickApply,
      };
      return newState;
    }
    case JobActionTypes.CLEAR_FORM:
      return {
        ...initialModelForm,
        quickApply: action.quickApply,
      };
    case JobDetailsActionTypes.INIT_JOB_DETAILS:
      return { ...initialModelForm, quickApply: state.quickApply };
    default:
      return state;
  }
};

export const company = (state = {}, action) => {
  switch (action.type) {
    case JobActionTypes.GET_SUCCESS: {
      return action.model.company;
    }
    default:
      return state;
  }
};

const isTemplate = (state = false, action) => {
  switch (action.type) {
    case JobActionTypes.GET:
      return false;
    case JobActionTypes.GET_SUCCESS:
    case JobDetailsActionTypes.GET_SUCCESS:
    case JobDetailsActionTypes.INIT_JOB_DETAILS_SUCCESS:
    case JobWorkplaceActionTypes.INIT_SUCCESS:
    case JobRatingCriteriasActionTypes.INIT_SUCCESS:
    case JobInternalDataActionTypes.INIT_JOB_INTERNAL_DATA_SUCCESS:
    case JobPictureActionTypes.GET_SUCCESS: {
      if (
        (action.model && action.model.status === jobStatusEnum.template) ||
        (action.modelForm &&
          action.modelForm.status === jobStatusEnum.template) ||
        (action.job && action.job.status === jobStatusEnum.template)
      ) {
        return true;
      }
      return false;
    }
    default:
      return state;
  }
};

const careerPages = (state = [], action) => {
  switch (action.type) {
    case JobActionTypes.GET_COMPANY_CAREER_PAGES_SUCCESS:
      return action.careerPages;
    case JobActionTypes.GET_COMPANY_CAREER_PAGES_FAIL:
      return state;
    default:
      return state;
  }
};

export default combineReducers({
  jobList,
  jobListSummary,
  statusList,
  statusLabels,
  model,
  isJobAnalysisLoading,
  jobAnalysis,
  isTemplate,
  company,
  careerPages,
  careerPagesAreLoading,
  isJobFieldFilledModalOpen,
});
