import { IconButton } from '@gupy/design-system';
import styled from 'styled-components';

const NavBarHeader = styled.div`
  box-shadow: ${({ boxShadow }) => boxShadow};
  background-color: ${({ backgroundColor }) => backgroundColor};
  display: ${({ display }) => display};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  width: ${({ width }) => width};
  min-height: ${({ minHeight }) => minHeight};
  z-index: ${({ zIndex }) => zIndex};
  box-sizing: ${({ boxSizing }) => boxSizing};
  position: ${({ position }) => position};

  > button {
    display: none;
    margin-left: 24px;
    margin-right: 16px;

    @media screen and (max-width: 1024px) {
      display: block;
    }
  }

  .rs-logo {
    display: none;

    @media screen and (max-width: 1024px) {
      display: block;
      max-height: 50px;
      width: 100%;
    }
  }
`;

const NavbarProducts = styled.nav`
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding-left: ${({ paddingLeft }) => paddingLeft};

  @media screen and (max-width: 1024px) {
    padding-left: ${({ paddingLeftMobile }) => paddingLeftMobile};
  }
`;

const ProductDiv = styled.div`
  display: ${({ display }) => display};
  gap: ${({ gap }) => gap};
  align-items: ${({ alignItems }) => alignItems};

  .navbar-logo {
    @media screen and (max-width: 1024px) {
      display: none;
    }
  }
`;

const ProductsList = styled.ul`
  list-style-type: ${({ listStyleType }) => listStyleType};
  display: ${({ display }) => display};
  align-items: ${({ alignItems }) => alignItems};
  padding-left: ${({ paddingLeft }) => paddingLeft};
  padding-right: ${({ paddingRight }) => paddingRight};
  margin: ${({ margin }) => margin};

  @media screen and (max-width: 1024px) {
    display: ${({ displayMobile }) => displayMobile};
  }
`;

const FixedProduct = styled.li`
  padding: ${({ padding }) => padding};
  background-color: ${({ backgroundColor }) => backgroundColor};
  margin-right: ${({ marginRight }) => marginRight};
  color: ${({ fontColor }) => fontColor};
  border-radius: ${({ borderRadius }) => borderRadius};
`;

const ProductTitle = styled.a`
  display: ${({ display }) => display};
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  padding: ${({ padding }) => padding};
  text-decoration: ${({ textDecoration }) => textDecoration};
  font-family: ${({ fontFamily }) => fontFamily};
  font-size: ${({ fontSize }) => fontSize};
  color: ${({ fontColor }) => fontColor};
  font-weight: ${({ fontWeight }) => fontWeight};
  line-height: ${({ lineHeight }) => lineHeight};
  gap: ${({ gap }) => gap};

  :hover {
    text-decoration: ${({ textDecorationHover }) => textDecorationHover};
    color: ${({ fontColorHover }) => fontColorHover};
  }
`;

const GupyProduct = styled.li`
  padding: ${({ padding }) => padding};
  margin-right: ${({ marginRight }) => marginRight};
  color: ${({ fontColor }) => fontColor};

  :hover {
    text-decoration: ${({ textDecorationHover }) => textDecorationHover};
    background-color: ${({ backgroundColorHover }) => backgroundColorHover};
    color: ${({ fontColorHover }) => fontColorHover};
    border-radius: ${({ borderRadiusHover }) => borderRadiusHover};
  }
`;

const FooterNavBar = styled.div`
  display: ${({ display }) => display};
  padding-right: ${({ paddingRight }) => paddingRight};

  .button-modal-discovery-products {
    display: ${({ displayButton }) => displayButton};
    align-items: ${({ alignItemsButton }) => alignItemsButton};
    border: ${({ borderButton }) => borderButton};
    border-radius: ${({ borderRadiusButton }) => borderRadiusButton};
    padding-left: ${({ paddingLeftButton }) => paddingLeftButton};

    @media screen and (max-width: 1024px) {
      border: ${({ borderButtonMobile }) => borderButtonMobile};
    }

    .modal-discovery-products-icon-button {
      padding-right: ${({ paddingRightIcon }) => paddingRightIcon};
      border: ${({ borderIcon }) => borderIcon};
      padding-left: ${({ paddingLeftIcon }) => paddingLeftIcon};
      background-color: ${({ backgroundColorIcon }) => backgroundColorIcon};
    }
  }
`;

const ButtonOpenSidebar = styled(IconButton)`
  display: ${({ display }) => display};
  margin-left: ${({ marginLeft }) => marginLeft};
  padding: ${({ padding }) => padding};
`;

const MobileProductNav = styled.div`
  display: ${({ display }) => display};
  color: ${({ color }) => color};
  border-top: ${({ borderTop }) => borderTop};
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  position: ${({ position }) => position};
  padding: ${({ padding }) => padding};
  top: ${({ top }) => top};
  right: ${({ right }) => right};
  z-index: ${({ zIndex }) => zIndex};
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

const MobileProductNavHeader = styled.p`
  font-family: ${({ fontFamily }) => fontFamily};
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  padding-left: ${({ paddingLeft }) => paddingLeft};
  padding-bottom: ${({ paddingBottom }) => paddingBottom};
`;

const MobileProductLink = styled.a`
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  color: ${({ color }) => color};

  & hover {
    text-decoration: ${({ hoverTextDecoration }) => hoverTextDecoration};
  }
`;

const MobileNavMenuOverlay = styled.div`
  display: ${({ display }) => display};
  position: ${({ position }) => position};
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  top: ${({ top }) => top};
  left: ${({ left }) => left};
  background-color: ${({ backgroundColor }) => backgroundColor};
  opacity: ${({ opacity }) => opacity};
  z-index: ${({ zIndex }) => zIndex};
`;

export default {
  NavBarHeader,
  NavbarProducts,
  ProductsList,
  FixedProduct,
  GupyProduct,
  ProductTitle,
  ProductDiv,
  FooterNavBar,
  ButtonOpenSidebar,
  MobileProductNav,
  MobileProductNavHeader,
  MobileProductLink,
  MobileNavMenuOverlay,
};
