import { combineReducers } from 'redux';
import { JobListActionTypes } from './Actions';

const initialJobList = {
  data: [],
  summary: {
    page: 0,
    perPage: 0,
    pageCount: 0,
    recordsTotal: 0,
    parameters: {},
    order: [],
  },
};

const initialSummary = {
  order: 'expiresAt',
  recruiters: [],
  managers: [],
  classifiers: [],
  approvers: [],
  creators: [],
  jobTypes: [],
  pagination: {
    pageCount: 1,
  },
  recordsTotal: 0,
  hasJobs: false,
};

export const statusList = (state = [], action) => {
  switch (action.type) {
    case JobListActionTypes.GET_ALL_SUCCESS:
      return action.statusList;
    default:
      return state;
  }
};

export const publicationTypeList = (state = [], action) => {
  switch (action.type) {
    case JobListActionTypes.GET_ALL_SUCCESS:
      return action.publicationTypeList;
    default:
      return state;
  }
};

export const jobList = (state = initialJobList, action) => {
  switch (action.type) {
    case JobListActionTypes.GET_ALL_SUCCESS: {
      return {
        data: action.jobList.data,
      };
    }
    default:
      return state;
  }
};

export const jobListIsLoading = (state = true, action) => {
  switch (action.type) {
    case JobListActionTypes.GET_ALL_SUCCESS:
    case JobListActionTypes.GET_ALL_FAIL:
    case JobListActionTypes.PIN_SUCCESS:
    case JobListActionTypes.PIN_FAIL:
    case JobListActionTypes.UNPIN_SUCCESS:
    case JobListActionTypes.UNPIN_FAIL:
      return false;
    case JobListActionTypes.GET_ALL:
    case JobListActionTypes.PIN:
      return true;
    default:
      return state;
  }
};

export const jobListSummary = (state = initialSummary, action) => {
  switch (action.type) {
    case JobListActionTypes.GET_ALL_SUCCESS: {
      return {
        ...state,
        recruiters: action.recruiters,
        managers: action.managers,
        classifiers: action.classifiers,
        approvers: action.approvers,
        creators: action.creators,
        jobTypes: action.jobTypes,
        pagination: {
          pageCount: action.jobList.summary.pageCount,
        },
        recordsTotal: action.jobList.summary.recordsTotal,
        hasJobs: action.jobList.summary.hasJobs,
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  jobList,
  jobListSummary,
  statusList,
  jobListIsLoading,
  publicationTypeList,
});
