import { Button, Card, H } from '@gupy/design-system';
import { GoogleAnalyticsHelper } from '@gupy/front-helpers';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import getKnowMoreLinkForPlan from '../../constants/CompaniesPlansToKnowMoreLinks';
import './styles.scss';

const propTypes = {
  children: PropTypes.node.isRequired,
  shouldBlock: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.node.isRequired,
  ]),
  btnText: PropTypes.string.isRequired,
  cardTitle: PropTypes.string.isRequired,
  cardDescription: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.node.isRequired,
  ]),
  onBtnClick: PropTypes.func,
  currentUser: PropTypes.object.isRequired,
  subDescription: PropTypes.string,
  gaButtonClickProps: PropTypes.shape({
    category: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
  gaKnowMoreLinkProps: PropTypes.shape({
    category: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
  knowMoreUrl: PropTypes.string,
};

const BlockedComponent = ({
  children,
  shouldBlock,
  title,
  description,
  btnText,
  cardTitle,
  cardDescription,
  onBtnClick,
  currentUser,
  subDescription,
  gaButtonClickProps = undefined,
  gaKnowMoreLinkProps = undefined,
  knowMoreUrl = '',
}) => {
  const showUpgradePlanButton =
    currentUser && ['root', 'owner'].includes(currentUser.userType);
  let companyPlan = '';
  if (currentUser && currentUser.products) {
    const companyProduct = currentUser.products.find(
      product => product.plan !== '',
    );
    if (companyProduct) {
      companyPlan = companyProduct.plan;
    }
  }
  const knowMoreLink = getKnowMoreLinkForPlan(companyPlan);
  const onClickKnowMoreLink = link => {
    if (gaButtonClickProps) {
      GoogleAnalyticsHelper.sendEvent({
        category: gaButtonClickProps.category,
        action: gaButtonClickProps.action,
        label: gaButtonClickProps.label,
      });
    }
    window.open(link, '_blank');
  };
  return shouldBlock ? (
    <Fragment>
      <div style={{ position: 'relative', width: '100%' }}>
        <main className="blocked-component-container">
          <section>
            <img
              className="blocked-component-container__image-plan"
              src={`${process.env.REACT_APP_ASSETS_URL}/repacking/higher-plan-logo.svg`}
              height="60px"
              alt="Logo"
            />
            <H variant="1" className="blocked-component-container__header">
              {title}
            </H>
            <p className="blocked-component-container__description">
              {description}
            </p>
            {subDescription && (
              <p className="blocked-component-container__subDescription">
                {subDescription}
              </p>
            )}
            {showUpgradePlanButton && (
              <div style={{ width: '100%' }}>
                <Button
                  size="lg"
                  className="blocked-component-container__button"
                  onClick={
                    onBtnClick || (() => onClickKnowMoreLink(knowMoreLink))
                  }
                >
                  {btnText}
                </Button>
              </div>
            )}
            <Card padding="md" className="blocked-component-container__card">
              <H variant="3" baseSpacing={false}>
                {cardTitle}
              </H>
              <p>
                {cardDescription}
                <a
                  href={knowMoreUrl}
                  onClick={() => {
                    if (gaKnowMoreLinkProps) {
                      GoogleAnalyticsHelper.sendEvent({
                        category: gaKnowMoreLinkProps.category,
                        action: gaKnowMoreLinkProps.action,
                        label: gaKnowMoreLinkProps.label,
                      });
                    }
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedHTMLMessage
                    id="blockedComponent_knowMoreLink"
                    defaultMessage="<span> Saiba mais.</span>"
                  />
                </a>
              </p>
            </Card>
          </section>
        </main>
      </div>
    </Fragment>
  ) : (
    children
  );
};

BlockedComponent.propTypes = propTypes;

export { BlockedComponent };
