import api from '../../api/DarthVaderClient';

const customSkill = '/custom-skill';
export default {
  getAll() {
    return api.get('/custom-skills');
  },
  delete(id) {
    return api.delete(`${customSkill}/${id}`);
  },
  search(searchTerm) {
    return api.get(`/job-management/skills?search=${searchTerm}`);
  },
  put(id, payload) {
    return api.put(`${customSkill}/${id}`, payload);
  },
  post(payload) {
    return api.post('/customize-skill', payload);
  },
};
