import { useFlag } from '@unleash/proxy-client-react';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import featuresFlagsEnum from '../Authentication/FeaturesFlagsEnum';
import SalesforceInjector from './SalesforceInjector';
import ZendeskInjector from './ZendeskInjector';

const propTypes = {
  currentUser: PropTypes.object,
  isZendeskWidgetEnabled: PropTypes.bool,
};

const ChatInjector = ({ currentUser, isZendeskWidgetEnabled }) => {
  const isSalesforceEnabled = useFlag(featuresFlagsEnum.salesforceChatWidget);

  return (
    <React.Fragment>
      {isSalesforceEnabled ? (
        <SalesforceInjector currentUser={currentUser} />
      ) : (
        <ZendeskInjector
          currentUser={currentUser}
          isFeatureFlagEnabled={isZendeskWidgetEnabled}
        />
      )}
    </React.Fragment>
  );
};

ChatInjector.propTypes = propTypes;

const mapStateToProps = ({ reducers }) => ({
  currentUser: reducers.Authentication && reducers.Authentication.currentUser,
  isZendeskWidgetEnabled:
    reducers.Authentication &&
    reducers.Authentication.currentUser &&
    reducers.Authentication.currentUser.featureFlags &&
    reducers.Authentication.currentUser.featureFlags.includes(
      featuresFlagsEnum.widgetZendeskCompanies,
    ),
});

export default connect(mapStateToProps)(ChatInjector);
