import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  currentUser: PropTypes.object,
};

const SalesforceInjector = ({ currentUser }) => {
  const [isInjected, setIsInjected] = React.useState(false);

  React.useEffect(() => {
    const isProduction = process.env.NODE_ENV === 'production';
    const srcUrl = isProduction
      ? 'https://gupy.my.salesforce.com/embeddedservice/menu/fab.min.js'
      : 'https://gupy--homologaca.sandbox.my.salesforce.com/embeddedservice/menu/fab.min.js';
    const envUrl = isProduction
      ? 'https://gupy.my.salesforce.com'
      : 'https://gupy--homologaca.sandbox.my.salesforce.com';
    const chatUrl = isProduction
      ? 'https://d.la1-core1.sfdc-xwy4ub.salesforceliveagent.com/chat'
      : 'https://d.la12s-core1.sfdc-xwy4ub.salesforceliveagent.com/chat';
    const organizationId = isProduction ? '00DHs000001v8DF' : '00DHa000001JW0P';

    if (isInjected || (currentUser && !currentUser.id)) {
      return;
    }

    const script = document.createElement('script');
    script.src = srcUrl;
    script.type = 'text/javascript';
    script.onload = () => {
      if (
        window.embedded_svc &&
        window.embedded_svc.menu &&
        window.embedded_svc.menu.init
      ) {
        window.embedded_svc.menu.init(
          envUrl,
          chatUrl,
          'https://service.force.com',
          organizationId,
          'Menu_Community',
        );
      }
    };
    const preScript = document.createElement('script');
    preScript.src = 'https://service.force.com/embeddedservice/menu/fab.min.js';
    preScript.type = 'text/javascript';
    document.body.appendChild(preScript);
    document.body.appendChild(script);
    setIsInjected(true);
  }, [currentUser]);

  return null;
};

SalesforceInjector.propTypes = propTypes;

export default SalesforceInjector;
