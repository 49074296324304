import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  messages: PropTypes.object.isRequired,
};

export const IntroContent = ({ messages }) => (
  <div>
    <h2 className="diversity-term__heading">{messages.introTitle}</h2>
    <p className="diversity-term__paragraph">
      {messages.introDescription}{' '}
      <strong>{messages.introDescriptionAccess}</strong>{' '}
      {messages.introDescriptionPermission}{' '}
      <a
        href="https://suporte.gupy.io/s/suporte/article/O-que-e-um-perfil-de-acesso?language=pt_BR"
        className="diversity-term__link"
        target="_blank"
      >
        {messages.introDescriptionFAQ}
      </a>
      .
    </p>
  </div>
);

IntroContent.propTypes = propTypes;
