import { combineReducers } from 'redux';
import SkillActionTypes from '../../constants/Skill/SkillActionTypes';

const customSkills = (state = [], action) => {
  switch (action.type) {
    case SkillActionTypes.GET_ALL_SUCCESS:
      return action.customSkills;
    case SkillActionTypes.GET_ALL_FAIL:
    default:
      return state;
  }
};

const skillsSuggestions = (state = [], action) => {
  switch (action.type) {
    case SkillActionTypes.SEARCH_SUCCESS:
      return action.skills;
    case SkillActionTypes.SEARCH_FAIL:
      return [];
    default:
      return state;
  }
};

export default combineReducers({
  customSkills,
  skillsSuggestions,
});
