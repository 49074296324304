import { buildToast, ToastTypes } from '@gupy/front-commons';
import { put, takeLatest } from 'redux-saga/effects';
import SkillActionTypes from '../../constants/Skill/SkillActionTypes';
import SkillForm from '../../forms/Skill/SkillForm';
import SkillService from '../../services/Skill/SkillService';

function* getAll() {
  try {
    const response = yield SkillService.getAll();
    yield put({
      type: SkillActionTypes.GET_ALL_SUCCESS,
      customSkills: response.body,
    });
  } catch (error) {
    yield put({
      type: SkillActionTypes.GET_ALL_FAIL,
    });
  }
}

function* post(action) {
  const { payload, messages } = action;
  const form = new SkillForm();

  form.populate(payload);

  const validation = false;

  if (validation) {
    yield put({
      type: SkillActionTypes.POST_FAIL,
      validation,
    });
  } else {
    try {
      yield SkillService.post(payload);

      yield put({
        type: SkillActionTypes.POST_SUCCESS,
        toast: buildToast(messages.success, ToastTypes.success),
      });
    } catch (error) {
      yield put({
        type: SkillActionTypes.POST_FAIL,
        validation: form.validateErrorResponse(error),
      });
    }
  }
}

function* deleteSkill(action) {
  const { id, messages } = action;

  try {
    const response = yield SkillService.delete(id);
    if (response.statusCode !== 200) {
      throw new Error(response.body.message);
    }
    yield put({
      type: SkillActionTypes.DELETE_SUCCESS,
      toast: buildToast(messages.skillDeleteSuccessMessage, ToastTypes.success),
    });
  } catch (error) {
    const form = new SkillForm();
    yield put({
      type: SkillActionTypes.DELETE_FAIL,
      validation: form.validateErrorResponse(error),
      toast: buildToast(error.body.message, ToastTypes.error),
    });
  }
}

function* search(action) {
  try {
    const response = yield SkillService.search(action.searchSkill);
    yield put({
      type: SkillActionTypes.SEARCH_SUCCESS,
      skills: response.body.results,
    });
  } catch (error) {
    yield put({
      type: SkillActionTypes.SEARCH_FAIL,
    });
  }
}

function* update(action) {
  const { payload, messages } = action;
  const form = new SkillForm();

  const { id } = payload;
  const newPayload = { ...payload, id: undefined };
  form.populate(newPayload);

  try {
    yield SkillService.put(id, newPayload);

    yield put({
      type: SkillActionTypes.PUT_SUCCESS,
      toast: buildToast(
        messages.skillUpdatedSuccessMessage,
        ToastTypes.success,
      ),
    });
  } catch (error) {
    yield put({
      type: SkillActionTypes.PUT_FAIL,
      validation: form.validateErrorResponse(error),
    });
  }
}

function* SkillSaga() {
  yield takeLatest(SkillActionTypes.GET_ALL, getAll);
  yield takeLatest(SkillActionTypes.DELETE, deleteSkill);
  yield takeLatest(SkillActionTypes.DELETE_SUCCESS, getAll);
  yield takeLatest(SkillActionTypes.SEARCH, search);
  yield takeLatest(SkillActionTypes.POST, post);
  yield takeLatest(SkillActionTypes.POST_SUCCESS, getAll);
  yield takeLatest(SkillActionTypes.PUT, update);
  yield takeLatest(SkillActionTypes.PUT_SUCCESS, getAll);
}

export default SkillSaga;
