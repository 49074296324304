import formatMessages from '@gupy/front-helpers/src/helpers/formatMessages';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  productRecruitmentAndSelectionAlt: {
    id: 'product-R&S-logo-alt',
    defaultMessage: 'Produto Recrutamento e Seleção',
  },
  menuOverview: {
    id: 'overview-menu-sidebar',
    defaultMessage: 'Visão geral',
  },
  vacancyManagement: {
    id: 'vacancy-management-menu-sidebar',
    defaultMessage: 'Gestão de vagas',
  },
  talentsManagement: {
    id: 'talent-management-menu-sidebar',
    defaultMessage: 'Gestão de talentos',
  },
  talents: {
    id: 'talents-subitem-menu-sidebar',
    defaultMessage: 'Talentos',
  },
  talentsPortal: {
    id: 'talents-portal-subitem-menu-sidebar',
    defaultMessage: 'Portal de Talentos',
  },
  segmentedLists: {
    id: 'segmented-lists-subitem-menu-sidebar',
    defaultMessage: 'Listas Segmentadas',
  },
  fundraisingPages: {
    id: 'fundraising-pages-subitem-menu-sidebar',
    defaultMessage: 'Páginas de Captação',
  },
  communications: {
    id: 'communications-subitem-menu-sidebar',
    defaultMessage: 'Comunicações',
  },
  dataAnalysis: {
    id: 'data-analysis-menu-sidebar',
    defaultMessage: 'Analisar dados',
  },
  reports: {
    id: 'reports-menu-sidebar',
    defaultMessage: 'Relatórios',
  },
  taskManagement: {
    id: 'task-management-menu-sidebar',
    defaultMessage: 'Gerenciar tarefas',
  },
  setup: {
    id: 'setup-menu-sidebar',
    defaultMessage: 'Setup',
  },
  gupyCommunity: {
    id: 'gupy-community-menu-sidebar',
    defaultMessage: 'Gupy Comunidade',
  },
  gupyRecruiter: {
    id: 'gupy-recruiter-menu-sidebar',
    defaultMessage: 'Gupy Recruiter',
  },
  gupyCoin: {
    id: 'gupy-coin-menu-sidebar',
    defaultMessage: 'Gupy Coin',
  },
  recognitions: {
    id: 'gupy-coin-recognitions-subitem-menu-sidebar',
    defaultMessage: 'Reconhecimentos',
  },
  store: {
    id: 'gupy-coin-store-subitem-menu-sidebar',
    defaultMessage: 'Loja',
  },
  orders: {
    id: 'gupy-coin-orders-subitem-menu-sidebar',
    defaultMessage: 'Compras',
  },
  logout: {
    id: 'logout-menu-sidebar',
    defaultMessage: 'Sair da conta',
  },
});

export const getMessages = intl => formatMessages(intl, messages);
