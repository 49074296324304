import { GoogleAnalyticsHelper } from '@gupy/front-helpers';
import { useKeycloak } from '@react-keycloak/web';
import { useFlag } from '@unleash/proxy-client-react';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { FormattedHTMLMessage, injectIntl, intlShape } from 'react-intl';
import featuresFlagsEnum from '../../containers/Authentication/FeaturesFlagsEnum';
import { loadCopilot } from '../../services/Copilot/CopilotService';
import FeedbackCopilot from './components/FeedBackCopilot/FeedbackCopilot';
import InsightsComponent from './components/InsightsCopilot/InsightsCopilot';
import { getMessages } from './Messages';
import './Copilot.scss';

const propTypes = {
  isOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
};

const CopilotFAQ = ({ isOpen, onClose, intl }) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');

  const message = getMessages(intl);

  const [isInsights, setInsights] = useState(false);

  const isEnabledFeedback = useFlag(featuresFlagsEnum.feedbackCopilot);
  const isEnabledInsights = useFlag(featuresFlagsEnum.insightsCopilot);

  const messagesEndRef = useRef(null);
  const { keycloak } = useKeycloak();

  const { token } = keycloak;

  const GA_ACTIONS = {
    sendQuestion: 'copilot-submit-question',
    suggestedQuestion: 'copilot-submit-suggested-question',
  };

  const sendMetricsToGA = (action, label) => {
    GoogleAnalyticsHelper.sendEvent({
      category: `COPILOT_SUBMIT_${action === GA_ACTIONS.sendQuestion ? 'QUESTION' : 'SUGGESTED_QUESTION'}`,
      action,
      label,
    });
  };

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  const handleSend = async () => {
    if (input.trim() === '') return;

    const userMessage = { sender: 'user-message_input', text: input };
    setMessages([...messages, userMessage]);

    setInput('');

    setTimeout(() => {
      const botMessage = { sender: 'bot-message', text: '...' };
      setMessages([...messages, userMessage, botMessage]);
    }, 200);

    sendMetricsToGA(GA_ACTIONS.sendQuestion, input);

    const resAPI = await loadCopilot(input, token);
    const messageResponse = {
      sender: 'bot-message_api',
      text: resAPI.data.answer.text,
      id: resAPI.data.answer.id,
    };
    setMessages([...messages, userMessage, messageResponse]);

    const textarea = document.querySelector('textarea');
    textarea.style.height = '40px';
    setInsights(false);
  };

  const handleSendQuestionSugestionPrimary = async () => {
    const question = {
      sender: 'user-message_input',
      text: message.userMessageSugestionPrimary,
    };
    setMessages([...messages, question]);

    setTimeout(() => {
      const botMessage = { sender: 'bot-message', text: '...' };
      setMessages([...messages, question, botMessage]);
    }, 200);

    sendMetricsToGA(
      GA_ACTIONS.suggestedQuestion,
      message.userMessageSugestionPrimary,
    );

    const resAPI = await loadCopilot(
      message.userMessageSugestionPrimary,
      token,
    );
    const messageResponse = {
      sender: 'bot-message_api',
      text: resAPI.data.answer.text,
      id: resAPI.data.answer.id,
    };
    setMessages([...messages, question, messageResponse]);
    setInsights(false);
  };

  const handleSendQuestionSugestionSecundary = async () => {
    const question = {
      sender: 'user-message_input',
      text: message.userMessageSugestionSecundary,
    };
    setMessages([...messages, question]);

    setTimeout(() => {
      const botMessage = { sender: 'bot-message', text: '...' };
      setMessages([...messages, question, botMessage]);
    }, 200);

    sendMetricsToGA(
      GA_ACTIONS.suggestedQuestion,
      message.userMessageSugestionSecundary,
    );

    const resAPI = await loadCopilot(
      message.userMessageSugestionSecundary,
      token,
    );
    const messageResponse = {
      sender: 'bot-message_api',
      text: resAPI.data.answer.text,
      id: resAPI.data.answer.id,
    };
    setMessages([...messages, question, messageResponse]);
    setInsights(false);
  };

  const handleChange = e => {
    setInput(e.target.value);
    e.preventDefault();

    const textarea = document.querySelector('textarea');
    textarea.style.height = '40px';
    const textHeight = e.target.scrollHeight;
    textarea.style.height = `${textHeight}px`;
  };

  const handleSendQuestionInsights = async () => {
    const question = {
      sender: 'user-message_input',
      text: message.userMessageSugestionInsights,
    };
    setMessages([...messages, question]);

    const botMessage = {
      sender: 'bot-message_insights',
      text: message.responseWhatInsights,
    };
    setMessages([...messages, question, botMessage]);

    sendMetricsToGA(
      GA_ACTIONS.suggestedQuestion,
      message.userMessageSugestionInsights,
    );

    setInsights(true);
  };

  const handleInsightsClick = async messageTime => {
    setMessages([...messages, ...messageTime]);
  };

  return (
    <div className={`copilot ${isOpen ? 'open' : 'closing'}`}>
      <div className="copilot-content">
        <header className="header">
          <section className="content-header">
            <div aria-label={message.labelHeader} className="title-header">
              <img
                alt=""
                src={`${process.env.REACT_APP_ASSETS_URL}/copilot/gupy_ia.svg`}
              />
              <h3 aria-hidden="true">{message.title}</h3>
              <img
                alt=""
                src={`${process.env.REACT_APP_ASSETS_URL}/copilot/beta.svg`}
              />
            </div>
            <div aria-label={message.labelCloseBtn}>
              <button
                aria-label={message.labelCloseBtn}
                className="button-close-copilot"
                onClick={onClose}
              >
                <img
                  alt=""
                  src={`${process.env.REACT_APP_ASSETS_URL}/copilot/close.svg`}
                />
              </button>
            </div>
          </section>
        </header>

        <body className="chatbox-body">
          <section className="content-body">
            <div
              aria-label={message.labelInitialQuestion}
              className="bot-message_initial"
              id="message1"
            >
              <p>
                <FormattedHTMLMessage
                  id="copilot_gupy_question_initial"
                  defaultMessage={`
                    Olá! Sou a Inteligência Artificial da Gupy! <br/><br/>
                      Você pode começar uma conversa comigo fazendo uma pergunta
                      sobre as <strong>funcionalidades</strong> do <strong>
                      Gupy Recrutamento & Seleção. <br/> <br/>
                      Como eu posso te ajudar?</strong>`}
                />
              </p>
            </div>
            <div className="message_suggestion">
              {isEnabledInsights ? (
                <button
                  aria-label={message.userMessageSugestionInsights}
                  className="user-message_suggestion"
                  onClick={handleSendQuestionInsights}
                >
                  {message.userMessageSugestionInsights}
                </button>
              ) : null}
              <button
                aria-label={message.labelSugestionPrimary}
                className="user-message_suggestion"
                onClick={handleSendQuestionSugestionPrimary}
              >
                {message.userMessageSugestionPrimary}
              </button>

              <button
                aria-label={message.labelSugestionSecundary}
                className="user-message_suggestion"
                onClick={handleSendQuestionSugestionSecundary}
              >
                {message.userMessageSugestionSecundary}
              </button>
            </div>
            <div className="message_input">
              {messages.map(msg => (
                <Fragment>
                  <div className={`${msg.sender}`} aria-label={msg.text}>
                    <p>{msg.text}</p>
                  </div>
                  {isEnabledFeedback && msg.sender === 'bot-message_api' ? (
                    <div className="copilot_feedback">
                      <FeedbackCopilot
                        message={message}
                        handleSend={handleSendQuestionSugestionSecundary}
                        answerId={msg.id}
                      />
                    </div>
                  ) : null}
                  {isInsights && msg.sender === 'bot-message_insights' ? (
                    <InsightsComponent
                      isOpen={() => setInsights(true)}
                      onClose={() => setInsights(false)}
                      handleSendFeedback={handleSendQuestionSugestionSecundary}
                      handleInsightsClick={handleInsightsClick}
                    />
                  ) : null}
                </Fragment>
              ))}
            </div>

            <div ref={messagesEndRef} />
          </section>
        </body>

        <footer className="footer-chatbox">
          <div className="content-footer">
            <textarea
              aria-label={message.inputPlaceHolder}
              placeholder={message.inputPlaceHolder}
              value={input}
              onChange={handleChange}
              onKeyUp={e => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  handleSend(e);
                }
              }}
            />
            <button
              className="button-send-message"
              type="submit"
              aria-label={message.send}
              onClick={handleSend}
            >
              <img
                alt=""
                src={`${process.env.REACT_APP_ASSETS_URL}/copilot/arrow.svg`}
              />
            </button>
          </div>
          <div
            className="footer-message"
            aria-label={message.labelFooterDescription}
          >
            <p>{message.footerDescription}</p>
          </div>
        </footer>
      </div>
    </div>
  );
};
CopilotFAQ.propTypes = propTypes;
export default injectIntl(CopilotFAQ);
