import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import ListProducts from './components/ListProducts';
import { getMessages } from './Messages';
import './Footer.scss';

const propTypes = {
  intl: intlShape,
};

const AuthenticationFooter = ({ intl }) => {
  const messages = getMessages(intl);
  return (
    <div className="footer-content">
      <img
        className="logo"
        src={`${process.env.REACT_APP_ASSETS_URL}/footer/Logo-white.svg`}
        aria-label={messages.logo}
        width="78"
        height="32"
      />
      <div className="footer-content__body">
        <section className="produtos">
          <h3>{messages.titleList}</h3>
          <ul aria-label={messages.titleList}>
            <ListProducts
              linkUrl={
                'https://www.gupy.io/software-de-recrutamento-e-selecao/?utm_medium=produto&utm_source=cross&utm_campaign=plg__footer__novo-footer-rs'
              }
              description={messages.linkRecruitment}
              logoProduct={`${process.env.REACT_APP_ASSETS_URL}/footer/R&S.svg`}
            />
            <ListProducts
              linkUrl={
                'https://www.gupy.io/software-de-admissao-digital?utm_medium=produto&utm_source=cross&utm_campaign=plg__footer__novo-footer-adm'
              }
              description={messages.linkAdmission}
              logoProduct={`${process.env.REACT_APP_ASSETS_URL}/footer/ADM.svg`}
            />
            <ListProducts
              linkUrl={
                'https://www.gupy.io/plataforma-de-educacao-corporativa?utm_medium=produto&utm_source=cross&utm_campaign=plg__footer__novo-footer-edc'
              }
              description={messages.linkEduCorp}
              logoProduct={`${process.env.REACT_APP_ASSETS_URL}/footer/EDC.svg`}
            />
            <ListProducts
              linkUrl={
                'https://www.gupy.io/plataforma-de-clima-e-engajamento?utm_medium=produto&utm_source=cross&utm_campaign=plg__footer__novo-footer-ce'
              }
              description={messages.linkClimateEngagement}
              logoProduct={`${process.env.REACT_APP_ASSETS_URL}/footer/C&E.svg`}
            />
            <ListProducts
              linkUrl={
                'https://www.gupy.io/plataforma-de-performance-e-desenvolvimento?utm_medium=produto&utm_source=cross&utm_campaign=plg__footer__novo-footer-pd'
              }
              description={messages.linkPerformanceDevelopment}
              logoProduct={`${process.env.REACT_APP_ASSETS_URL}/footer/P&D.svg`}
            />
            <ListProducts
              linkUrl={
                'https://www.gupy.io/sobre-a-gupy?utm_medium=produto&utm_source=cross&utm_campaign=plg__footer__novo-footer-sobre'
              }
              description={messages.linkGupy}
            />
            <ListProducts
              linkUrl={
                'https://www.gupy.io/precos?utm_medium=produto&utm_source=cross&utm_campaign=plg__footer__novo-footer-planos'
              }
              description={messages.linkPlans}
            />
          </ul>
        </section>
        <section className="rh-contents">
          <h3>{messages.titleList2}</h3>
          <ul aria-label={messages.titleList2}>
            <ListProducts
              linkUrl={
                'https://hr4results.gupy.io/?utm_medium=produto&utm_source=cross&utm_campaign=plg__footer__novo-footer-hr'
              }
              aria-label="HR for results"
              description={messages.linkHR4results}
            />
            <ListProducts
              linkUrl={
                'https://www.gupy.io/blog?utm_medium=produto&utm_source=cross&utm_campaign=plg__footer__novo-footer-blog'
              }
              description={messages.linkBlogGupy}
            />
            <ListProducts
              linkUrl={
                'https://academy.gupy.io/pt-br/?utm_medium=produto&utm_source=cross&utm_campaign=plg__footer__novo-footer-academy'
              }
              description={messages.linkGupyAcademy}
            />
            <ListProducts
              linkUrl={
                'https://www.gupy.io/materiais-rh?utm_medium=produto&utm_source=cross&utm_campaign=plg__footer__novo-footer-materiais'
              }
              description={messages.linkMaterialsAll}
            />
          </ul>
        </section>
        <section className="contato">
          <h3>{messages.titleList3}</h3>
          <ul aria-label={messages.titleList3}>
            <ListProducts
              linkUrl={
                'https://www.gupy.io/imprensa?utm_medium=produto&utm_source=cross&utm_campaign=plg__footer__novo-footer-imprensa'
              }
              description={messages.linkEvents}
            />
            <ListProducts
              linkUrl={'https://suporte.gupy.io/s/?language=pt_BR'}
              description={messages.linkSupportCompanies}
            />
            <ListProducts
              linkUrl={
                'https://suporte-candidatos.gupy.io/s/suporte?language=pt_BR'
              }
              description={messages.linkSupportCandidates}
            />
            <ListProducts
              linkUrl={'https://suporte.gupy.io/'}
              description={messages.linkFinancialSupport}
            />
          </ul>
        </section>
      </div>

      <span
        className="footer-content__message"
        aria-label={messages.gupyFooter}
      >
        {messages.gupyFooter}
      </span>
    </div>
  );
};
AuthenticationFooter.propTypes = propTypes;

export default injectIntl(AuthenticationFooter);
