import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

const anchorCss = css`
  width: ${({ width }) => width};
  font-weight: ${({ fontWeight }) => fontWeight};
  color: ${({ fontColor }) => fontColor};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  padding: ${({ padding }) => padding};
  border-radius: ${({ borderRadius }) => borderRadius};
  display: ${({ display }) => display};
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justifyContent }) => justifyContent};
  text-indent: ${({ textIndent }) => textIndent};
  cursor: ${({ cursor }) => cursor};

  &:hover {
    ${({ itemHoverStyles, linkHoverStyles }) =>
      itemHoverStyles &&
      linkHoverStyles &&
      css`
        background-color: ${itemHoverStyles.backgroundColor};
        color: ${linkHoverStyles.fontColor};
      `}

    svg {
      ${({ iconHoverStyles }) =>
        iconHoverStyles &&
        css`
          fill: ${iconHoverStyles.color};
        `}
    }
  }
`;

const LinkItem = styled(Link)`
  ${anchorCss}
`;

const Item = styled.a`
  ${anchorCss}
`;

const ItemIcon = styled.img`
  ${({ iconStyles }) =>
    iconStyles &&
    css`
      width: ${iconStyles.width};
      height: ${iconStyles.height};
    `}
`;

const subItemCss = css`
  ${({ subItemStyles }) =>
    subItemStyles &&
    css`
      display: ${subItemStyles.display};
      color: ${subItemStyles.color};
      text-decoration: ${subItemStyles.textDecoration};
      padding: ${subItemStyles.padding};
      border-radius: ${subItemStyles.borderRadius};
      cursor: ${subItemStyles.cursor};
    `}

  &:hover {
    ${({ itemHoverStyles, linkHoverStyles }) =>
      itemHoverStyles &&
      linkHoverStyles &&
      css`
        background-color: ${itemHoverStyles.backgroundColor};
        color: ${linkHoverStyles.fontColor};
      `}
  }
`;

const LinkSubItem = styled(Link)`
  ${subItemCss}
`;

const SubItem = styled.a`
  ${subItemCss}
`;

export default {
  Item,
  LinkItem,
  ItemIcon,
  SubItem,
  LinkSubItem,
};
