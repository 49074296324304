import { Spinner } from '@gupy/design-system';
import { UnleashProvider as Provider } from '@gupy/front-commons';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getCompanyIdBySubdomain as getCompanyIdBySubdomainAction } from './Authentication/Actions';
import { useSubdomain } from './Authentication/helpers';
import { CompanyError } from './Error/CompanyError';

const propTypes = {
  children: PropTypes.node.isRequired,
  companyId: PropTypes.number,
  getCompanyIdBySubdomain: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
  has404Error: PropTypes.bool,
};

const UnleashProvider = ({
  children,
  companyId,
  getCompanyIdBySubdomain,
  hasError,
  has404Error,
}) => {
  const subdomain = useSubdomain();
  useEffect(() => {
    getCompanyIdBySubdomain(subdomain);
  }, [subdomain]);

  if (hasError) {
    return <CompanyError isNotFoundError={has404Error} />;
  }

  return companyId ? (
    <Provider
      appName="ats-companies"
      clientKey={process.env.REACT_APP_UNLEASH_CLIENT_KEY}
      companyId={companyId}
      environment={process.env.NODE_ENV}
    >
      {children}
    </Provider>
  ) : (
    <div className="companies-privateRoute-spinner">
      <Spinner size={60} />
    </div>
  );
};

UnleashProvider.propTypes = propTypes;

const mapStateToProps = state => {
  const { company, companyError } = state.reducers.Signin;
  const hasError = !!companyError;
  const has404Error = hasError && companyError.statusCode === 404;

  return {
    companyId: company && company.id,
    hasError,
    has404Error,
  };
};

export default connect(mapStateToProps, {
  getCompanyIdBySubdomain: getCompanyIdBySubdomainAction,
})(UnleashProvider);
