import { Button, IconEnum } from '@gupy/design-system';
import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import { getMessages } from '../../Messages';
import './RscLinkedinIntegrationCreateChildApp.scss';

const RscLinkedinIntegrationCreateChildApp = ({
  intl,
  handleChildAppCreateSubmit,
  isCreateChildAppButtonDisabled,
}) => {
  const messages = getMessages(intl);
  return (
    <div className="job-board-panel-rsc-create-child-app">
      <div className="job-board-panel-rsc-create-child-app__description">
        {messages.rscIntegrationDisabledMessage}{' '}
        <a
          href="https://suporte.gupy.io/s/suporte/article/Tutorial-Conectando-o-LinkedIn-Recruiter-com-a-Gupy-atraves-do-Recruiter-System-Connect-RSC?language=pt_BR"
          target="_blank"
          className="job-board-panel-rsc-create-child-app__description-link"
        >
          {messages.rscIntegrationDisabledKnowMore}
        </a>
      </div>
      <div className="job-board-panel-rsc-create-child-app__button-wrapper">
        <Button
          className="job-board-panel-rsc-create-child-app__button"
          icon={IconEnum.Loop}
          type="outlined"
          color="primary"
          onClick={handleChildAppCreateSubmit}
          disabled={isCreateChildAppButtonDisabled}
        >
          {messages.rscConnectButton}
        </Button>
      </div>
    </div>
  );
};

RscLinkedinIntegrationCreateChildApp.propTypes = {
  intl: PropTypes.object.isRequired,
  handleChildAppCreateSubmit: PropTypes.func.isRequired,
  isCreateChildAppButtonDisabled: PropTypes.bool.isRequired,
};

export default injectIntl(RscLinkedinIntegrationCreateChildApp);
