import {
  Breadcrumb,
  BreadcrumbItem,
  Icon,
  IconEnum,
  Infotip,
  InputGroup,
  SwitchButton,
  Tip,
} from '@gupy/design-system';
import { TextEditor } from '@gupy/front-commons';
import { useFlag } from '@unleash/proxy-client-react';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  defineMessages,
  FormattedHTMLMessage,
  FormattedMessage,
  injectIntl,
  intlShape,
} from 'react-intl';
import featuresFlagsEnum from '../../../containers/Authentication/FeaturesFlagsEnum';
import { InputNumberMask } from '../CustomTestComponents';
import './CustomTestForm.scss';

const CustomTestForm = ({
  isViewOnlyMode,
  isRandomQuestions,
  model,
  onFieldChange,
  validation,
  intl,
}) => {
  const [isSwitchChecked, setIsSwitchChecked] = useState(false);

  useEffect(() => {
    setIsSwitchChecked(isRandomQuestions);
  }, [isRandomQuestions]);

  const common = (name, defaultValue = '') => ({
    name,
    id: name,
    value: model[name] || defaultValue,
    validation: validation[name],
    onChange: onFieldChange,
  });

  const isCustomTestRandomQuestionsEnabled = useFlag(
    featuresFlagsEnum.customTestRandomQuestions,
  );

  const messages = defineMessages({
    durationPlaceholder: {
      id: 'test_duration_placeholder',
      defaultMessage: 'Exemplo: 60 minutos',
    },
    knowMoreLink: {
      id: 'custom_test_know_more_link',
      defaultMessage: 'Clique aqui para saber mais sobre testes customizados',
    },
    knowMoreLinkUrl: {
      id: 'custom_test_know_more_link_url',
      defaultMessage:
        'https://suporte.gupy.io/s/suporte/article/Como-criar-e-utilizar-testes-customizados?language=pt_BR',
    },
  });

  return (
    <div className="custom-test-form">
      <Breadcrumb fluid>
        <BreadcrumbItem
          label={
            <FormattedMessage id="all_tests" defaultMessage="Todos os testes" />
          }
          linkTo="/companies/setup/tests?expanded=custom-tests"
        />
        <li>
          {model.title || (
            <FormattedMessage id="create_test" defaultMessage="Novo teste" />
          )}
        </li>
      </Breadcrumb>
      {isViewOnlyMode && (
        <Tip
          className="custom-test-form__view-only-tip"
          type="warning"
          showIcon
          text={
            <div>
              <FormattedHTMLMessage
                id="prevent_in_use_custom_test_change_warning"
                defaultMessage={
                  '<b>Esse teste está vinculado à uma vaga e não pode ser editado.</b><br/>Realizar edições nesse teste podem gerar inconsistências nas notas e prejuízos para a experiência das pessoas candidatas. Para realizar edições, é recomendado que esse teste seja duplicado.'
                }
              />
              <span>
                {' '}
                <a
                  target="_blank"
                  className="custom-tests-form__know-more-link"
                  href={intl.formatMessage(messages.knowMoreLinkUrl)}
                >
                  {intl.formatMessage(messages.knowMoreLink)}
                </a>
                .
              </span>
            </div>
          }
        />
      )}
      <div className="row">
        <div className="col-xs-9">
          <InputGroup
            label={
              <div className="custom-test-form__infotip-label">
                <span className="custom-test-form__infotip-message">
                  <FormattedMessage
                    id="test_title_label_message"
                    defaultMessage="Dê um nome ao teste"
                  />{' '}
                  *
                </span>
                <Infotip
                  id="test_title_label"
                  message={
                    <FormattedMessage
                      id="test_title_label_tooltip"
                      defaultMessage="Registre um nome para o seu teste. Esta informação ficará visível aos candidatos"
                    />
                  }
                >
                  <Icon icon={IconEnum.Info} />
                </Infotip>
              </div>
            }
            id="title"
            name="title"
            value={model.title || ''}
            validation={validation.title}
            disabled={isViewOnlyMode}
            onChange={onFieldChange}
            block
          />
        </div>
        <div className="col-xs-3 test-time-limit">
          <InputNumberMask
            label={
              <div className="custom-test-form__infotip-label">
                <span className="custom-test-form__infotip-message">
                  <FormattedMessage
                    id="test_time_limit_label_message"
                    defaultMessage="Duração do teste"
                  />{' '}
                  *
                </span>
                <Infotip
                  id="test_time_limit_label"
                  message={
                    <FormattedMessage
                      id="test_time_limit_label_tooltip"
                      defaultMessage="Determine a duração do teste (em minutos), caso necessário"
                    />
                  }
                >
                  <Icon icon={IconEnum.Info} />
                </Infotip>
              </div>
            }
            placeholder={intl.formatMessage(messages.durationPlaceholder)}
            mask={{
              suffix: ' minutos',
              integerLimit: 5,
            }}
            {...common('timeLimit')}
            value={model.timeLimit ? model.timeLimit.toString() : undefined}
            disabled={isViewOnlyMode}
          />
        </div>
      </div>
      <TextEditor
        label={
          <div className="custom-test-form__infotip-label">
            <span className="custom-test-form__infotip-message">
              <FormattedMessage
                id="test_instructions_label_message"
                defaultMessage="Registre as instruções do teste"
              />{' '}
              *
            </span>
            <Infotip
              id="test_instructions_label"
              message={
                <FormattedMessage
                  id="test_instructions_label_tooltip"
                  defaultMessage="Descreva as instruções necessárias para que o candidato possa realizar o teste. Esta informação será exibida antes do início do teste"
                />
              }
            >
              <Icon icon={IconEnum.Info} />
            </Infotip>
          </div>
        }
        {...common('instructions')}
        disabled={isViewOnlyMode}
      />
      {isCustomTestRandomQuestionsEnabled && (
        <SwitchButton
          id="switch-custom-test-form-random-questions"
          name="switch-custom-test-form-random-questions"
          disabled={isViewOnlyMode}
          checked={isSwitchChecked}
          onChange={value =>
            onFieldChange({ target: { name: 'isRandomQuestions', value } })
          }
          label={
            <div className="custom-test-form__random-questions-infotip-label">
              <span className="custom-test-form__random-questions-infotip-message">
                <FormattedMessage
                  id="custom-test-form-random-questions_label_message"
                  defaultMessage="Perguntas em ordem aleatória"
                />
              </span>
              <Infotip
                id="custom-test-form-random-questions_label"
                message={
                  <FormattedMessage
                    id="custom-test-form-random-questions_label_tooltip"
                    defaultMessage="As perguntas serão apresentadas em ordem aleatória para cada pessoa candidata."
                  />
                }
              >
                <Icon icon={IconEnum.Info} />
              </Infotip>
            </div>
          }
        />
      )}
    </div>
  );
};

CustomTestForm.propTypes = {
  isSaving: PropTypes.bool.isRequired,
  model: PropTypes.object.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  validation: PropTypes.object.isRequired,
  intl: intlShape,
};

export default injectIntl(CustomTestForm);
