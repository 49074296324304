import React from 'react';
import { FormattedMessage } from 'react-intl';

function Error500() {
  return (
    <div className="error-page">
      <span className="error-page__number">500</span>
      <h6>
        <FormattedMessage
          id="error-500"
          defaultMessage="Ops! Algo fora do comum aconteceu com nossos servidores. Voltaremos em breve!"
        />
      </h6>
    </div>
  );
}

export default Error500;
