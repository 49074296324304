const LocalstorageConstants = {
  companyToken: 'company_token',

  // HomeJobWeekInsights
  lowApplicationJobsInsights: 'last_closed_low_application_jobs_insights',
  descriptionErrorsInsights: 'last_closed_description_errors_jobs_insights',

  groupTypeNewModal: 'group-type-new-modal',
  dontShowTipsAgain: 'dont_show_tips_again',

  jobsWithQualifiedApplicationsInsights:
    'last_closed_qualified_applications_insights',
  jobsCloseToExpireInsights: 'last_closed_jobs_close_to_expire_insights',
  hasOpenedAutoValidationDialog: 'hasOpenedAutoValidationDialog',

  rscLinkedinPopupClosedSuccess: 'rsc_linkedin_popup_closed_success',
  talentsFilters: 'talents_filters',

  hideCopilotInfotip: 'hide_infotip_copilot',

  useKanbanSelectionProcess: 'use_kanban',
  applicationsFilters: 'applications_filters',
  jobManagementFilters: 'job_management_filters',
  useRedesign: 'use_redesign',
};

export const keysToBeKeptAfterSignout = [
  LocalstorageConstants.lowApplicationJobsInsights,
  LocalstorageConstants.descriptionErrorsInsights,
  LocalstorageConstants.groupTypeNewModal,
  LocalstorageConstants.dontShowTipsAgain,
  LocalstorageConstants.jobsWithQualifiedApplicationsInsights,
  LocalstorageConstants.jobsCloseToExpireInsights,
  LocalstorageConstants.hasOpenedAutoValidationDialog,
  LocalstorageConstants.talentsFilters,
  LocalstorageConstants.hideCopilotInfotip,
  LocalstorageConstants.useKanbanSelectionProcess,
  LocalstorageConstants.jobManagementFilters,
];

export default LocalstorageConstants;
