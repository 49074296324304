import { useMediaQuery } from '@gupy/front-commons';
import PropTypes from 'prop-types';
import React from 'react';
import Grid from '../../../components/Grid/Grid';
import SecondaryMenu from './SecondaryMenu/SecondaryMenu';
import SecondaryMenuOverflow from './SecondaryMenu/SecondaryMenuOverflow';
import './JobContainer.scss';

const propTypes = {
  children: PropTypes.node.isRequired,
  messages: PropTypes.object.isRequired,
  model: PropTypes.object.isRequired,
  onExitClick: PropTypes.func,
  mobileMenuContent: PropTypes.element,
  isSaving: PropTypes.bool,
};

const defaultProps = {
  mustSaveAndClose: false,
  onExitClick: null,
};

const JobContainer = ({
  children,
  messages,
  model,
  onExitClick,
  mobileMenuContent,
  isSaving,
}) => {
  const isMax = useMediaQuery(620);
  const maxLengthMobile = 34;
  const maxLengthDesktop = 55;
  return (
    <Grid>
      <SecondaryMenu
        messages={messages}
        model={model}
        onExitClick={onExitClick}
        menuContent={mobileMenuContent}
        isDisabled={isSaving}
      >
        <div className="new-secondary-menu__column">
          <p className="new-secondary-menu__title">
            {model.quickApply
              ? messages.quickApplyJobMenuTitle
              : messages.menuTitle}
          </p>
          <div className="new-secondary-menu__row">
            <span className="new-secondary-menu__describe">
              {model.name ? (
                <SecondaryMenuOverflow
                  id={model.name}
                  length={isMax ? maxLengthDesktop : maxLengthMobile}
                >
                  {model.name}
                </SecondaryMenuOverflow>
              ) : (
                `[${messages.menuNewJob}]`
              )}
            </span>
            <span
              className={`
              new-secondary-menu__status
              new-secondary-menu__status--${model.status}
            `}
            >
              {messages[model.status]}
            </span>
          </div>
        </div>
      </SecondaryMenu>
      <div className="job-container">{children}</div>
    </Grid>
  );
};

JobContainer.propTypes = propTypes;
JobContainer.defaultProps = defaultProps;

export default JobContainer;
