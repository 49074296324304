import { asyncActionType, createActionTypes } from '@gupy/front-commons';
import moment from 'moment';
import { adaptAdvancedSearchSummary } from '../../containers/Job/Utils/JobSearchUtils';
import JobPermissionsManager from '../../managers/JobPermissionsManager';

const JobListActionTypes = createActionTypes(
  'JobListV2',
  asyncActionType('GET_ALL'),
  asyncActionType('DELETE'),
  asyncActionType('DUPLICATE'),
  asyncActionType('DUPLICATE_AS_TEMPLATE'),
  asyncActionType('PATCH_JOB_STATUS'),
  asyncActionType('PIN'),
  asyncActionType('UNPIN'),
  asyncActionType('GET_JOB_TO_APPROVE'),
  asyncActionType('GET_JOB_TO_DISAPPROVE'),
  asyncActionType('FREEZE_JOB'),
  asyncActionType('CANCEL_JOB'),
);

const adaptUsersToSelect = users => {
  if (!users) return [];
  return users.map(user => ({
    label: user.name,
    value: user.id.toString(),
  }));
};

const formatDatabaseDate = date => {
  if (!date || date === '') {
    return null;
  }
  return moment.utc(date).format('DD/MM/YYYY');
};

const getAllJobs = (summary, isAdvancedSearchDisabled) => ({
  type: JobListActionTypes.GET_ALL,
  summary,
  isAdvancedSearchDisabled,
  adaptedSummary: adaptAdvancedSearchSummary(summary, isAdvancedSearchDisabled),
});

function orderStatus(status) {
  const orderedStatus = [
    'published',
    'draft',
    'approved',
    'closed',
    'waiting_approval',
    'canceled',
    'frozen',
    'disapproved',
  ];
  return orderedStatus
    .map(os => {
      const statusFound = status.find(s => s.value === os);
      return statusFound
        ? {
            value: os,
            count: statusFound.count,
          }
        : undefined;
    })
    .filter(s => !!s);
}

const getAllJobsSuccess = (jobList, filters, publicationType, location) => {
  const data = JobPermissionsManager.adaptJobsPermissions(jobList.data);
  const managersWithNoManager = [{ id: -1 }, ...filters.managers];
  const recruitersWithNoRecruiter = [{ id: -1 }, ...filters.recruiters];
  const approversWithNoApprover = [{ id: -1 }, ...filters.approvers];
  return {
    type: JobListActionTypes.GET_ALL_SUCCESS,
    jobList: {
      summary: jobList.summary,
      data: data.map(job => ({
        ...job,
        publishedAtLabel: formatDatabaseDate(job.publishedAt),
        registerEndDateLabel: formatDatabaseDate(job.registerEndDate),
        expiresAtLabel: formatDatabaseDate(job.expiresAt),
        requestedAt: formatDatabaseDate(job.requestedAt),
        approvedAt: formatDatabaseDate(job.approvedAt),
      })),
    },
    statusList: orderStatus(filters.status),
    publicationTypeList: filters.publicationTypes,
    publicationType,
    location,
    involved: filters.involved,
    recruiters: adaptUsersToSelect(recruitersWithNoRecruiter),
    managers: adaptUsersToSelect(managersWithNoManager),
    classifiers: adaptUsersToSelect(filters.classifiers),
    approvers: adaptUsersToSelect(approversWithNoApprover),
    creators: adaptUsersToSelect(filters.creators),
    jobTypes: filters.jobTypes,
  };
};

const deleteJob = (
  jobId,
  summary,
  jobPath,
  messages,
  isAdvancedSearchDisabled,
  payload,
) => ({
  type: JobListActionTypes.DELETE,
  jobId,
  summary,
  jobPath,
  messages,
  isAdvancedSearchDisabled,
  payload,
});

const duplicateJob = (jobId, jobPath, messages) => ({
  type: JobListActionTypes.DUPLICATE,
  jobId,
  jobPath,
  messages,
});

const duplicateJobAsTemplate = (jobId, messages) => ({
  type: JobListActionTypes.DUPLICATE_AS_TEMPLATE,
  jobId,
  messages,
});

const patchJobStatus = (
  payload,
  summary,
  jobPath,
  messages,
  isAdvancedSearchDisabled,
) => ({
  type: JobListActionTypes.PATCH_JOB_STATUS,
  payload,
  summary,
  jobPath,
  messages,
  isAdvancedSearchDisabled,
});

/**
 * Function to freeze a job.
 *
 * @param {type} payload - The payload data for freezing the job
 * @param {type} summary - The summary of the job to freeze
 * @param {type} messages - Messages related to freezing the job
 * @param {type} isAdvancedSearchDisabled - Flag indicating if advanced search is disabled
 * @return {type} An object representing the freeze action with payload, summary, messages,
 * and advanced search status
 */
const freezeJob = (payload, summary, messages, isAdvancedSearchDisabled) => ({
  type: JobListActionTypes.FREEZE_JOB,
  payload,
  summary,
  messages,
  isAdvancedSearchDisabled,
});

const pinJob = (jobId, summary, isAdvancedSearchDisabled) => ({
  type: JobListActionTypes.PIN,
  jobId,
  summary,
  isAdvancedSearchDisabled,
});

const unpinJob = (jobId, summary, isAdvancedSearchDisabled) => ({
  type: JobListActionTypes.UNPIN,
  jobId,
  summary,
  isAdvancedSearchDisabled,
});

const getJobToApprove = jobId => ({
  type: JobListActionTypes.GET_JOB_TO_APPROVE,
  payload: jobId,
});

const getJobToDisapprove = jobId => ({
  type: JobListActionTypes.GET_JOB_TO_DISAPPROVE,
  payload: jobId,
});

const cancelJob = (data, summary, isAdvancedSearchDisabled) => ({
  type: JobListActionTypes.CANCEL_JOB,
  payload: {
    data: {
      reason: data.reason,
      observation: data.observation,
    },
    jobId: data.jobId,
    messages: data.messages,
    summary,
    isAdvancedSearchDisabled,
  },
});

export {
  JobListActionTypes,
  getAllJobs,
  getAllJobsSuccess,
  deleteJob,
  duplicateJob,
  duplicateJobAsTemplate,
  patchJobStatus,
  freezeJob,
  pinJob,
  unpinJob,
  getJobToApprove,
  getJobToDisapprove,
  cancelJob,
};
