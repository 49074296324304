import { Button, Spinner } from '@gupy/design-system';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { clearSecondaryMenuForm, getJob } from '../../../actions/Job/JobAction';
import GridContent from '../../../components/Grid/GridContent';
import GridSidebarV2 from '../../../components/Grid/GridSidebarV2';
import jobStatusEnum from '../../../constants/Job/jobStatusEnum';
import JobPermissionsManager from '../../../managers/JobPermissionsManager';
import JobActions from '.././components/JobActions';
import JobContainer from '../components/JobContainer';
import { getJobMessages } from '../Messages';
import JobTemplatesDropdown from './components/JobTemplatesDropdown';
import { createJobFromTemplate, initJobOverview } from './JobOverviewAction';
import './JobOverview.scss';

const propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  Job: PropTypes.object.isRequired,
  JobOverview: PropTypes.object.isRequired,
  initJobOverview: PropTypes.func.isRequired,
  createJobFromTemplate: PropTypes.func.isRequired,
  getJob: PropTypes.func.isRequired,
  clearSecondaryMenuForm: PropTypes.func.isRequired,
  permissions: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
};

class JobOverviewContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      templateId: undefined,
    };
    this.messages = getJobMessages(props.intl);
    const quickApplyParam = new URLSearchParams(props.location.search).get(
      'quick-apply',
    );
    this.quickApply = String(quickApplyParam).toLowerCase() === 'true';
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.getJob(this.props.match.params.id);
    } else {
      this.props.initJobOverview();
      this.props.clearSecondaryMenuForm(this.quickApply);
    }
  }

  handleOnChange = event => {
    const templateId = event.target.value;
    this.setState({ templateId });
    this.props.createJobFromTemplate(
      templateId,
      '/companies/jobs/edit/:id/details',
    );
  };

  handleCreateJob = () => {
    const { id } = this.props.match.params;
    this.props.history.push(
      `/companies/jobs/${id ? `edit/${id}` : 'create'}/details`,
    );
  };

  handleExitClick = () => {
    this.props.history.push('/companies/jobs');
  };

  render() {
    const { permissions, JobOverview, Job } = this.props;
    const { id } = this.props.match.params;
    const isNewJob = !id;
    const jobPermissions = Job.model.permissions;
    const canAccess =
      jobPermissions && JobPermissionsManager.canEdit(jobPermissions);
    const canRequestApproval =
      JobPermissionsManager.canRequestApproval(permissions);
    const canPublish = JobPermissionsManager.canPublish(permissions);

    if (!isNewJob && jobPermissions && !canAccess) {
      this.props.history.push('/companies/403');
    }

    const { templates, isCreatingJob, isLoading } = JobOverview;

    const isJobPublished = Job.model.status === jobStatusEnum.published;

    const isFirstBlockDisabled =
      isJobPublished && !jobPermissions.edit_job_published;

    const isBlockDisabled = isNewJob || (canRequestApproval && !canPublish);

    const gridSidebarTitle = isNewJob
      ? this.messages.newJobs
      : this.messages.jobManagement;

    const isEditingJob = this.props.match.params.id;
    const hasTemplates = templates && templates.length;
    const showTemplateDropDown =
      permissions.create_job_draft_from_template &&
      hasTemplates &&
      !isEditingJob;

    const renderTextDescription = isEditingJob
      ? this.messages.whatIsTheJobDescription
      : this.messages.whatIsTheJobCreate;

    return (
      <JobContainer
        messages={this.messages}
        model={Job.model}
        onExitClick={this.handleExitClick}
      >
        <GridSidebarV2
          title={gridSidebarTitle}
          description={this.messages.newJobsDescription}
        />
        <GridContent
          headerClassName="job-overview-list__title"
          title={gridSidebarTitle}
          className="job-overview-list__content"
        >
          {isLoading ? (
            <div className="job-overview-list__spinner">
              <Spinner />
            </div>
          ) : (
            <Fragment>
              <JobActions jobModel={Job.model} />
              <ul className="job-overview-list">
                <li
                  className={classNames('job-overview-item', {
                    disabled: isFirstBlockDisabled,
                  })}
                >
                  <fieldset className="job-overview-item__fieldset">
                    <legend
                      className="job-overview-item__legend"
                      id="what-is-the-job"
                    >
                      <span className="job-overview-item__number">01</span>
                      <h2 translate="no" className="job-overview-item__title">
                        {this.messages.whatIsTheJob}
                      </h2>
                    </legend>
                    <p
                      className="job-overview-item__description"
                      id="what-is-the-job-description"
                    >
                      {renderTextDescription}
                    </p>
                    <ul
                      role="group"
                      aria-labelledby="what-is-the-job what-is-the-job-description"
                      className="job-overview-list__actions"
                    >
                      {permissions.create_job_draft && !isEditingJob ? (
                        <li>
                          <Button
                            id="create-new-job-btn"
                            className="job-overview-item__button job-overview-item__button--first"
                            disabled={isFirstBlockDisabled || isCreatingJob}
                            onClick={this.handleCreateJob}
                            size="md"
                          >
                            {this.messages.newJob}
                          </Button>
                        </li>
                      ) : null}
                      {isEditingJob ? (
                        <li>
                          <Button
                            className="job-overview-item__button"
                            disabled={isFirstBlockDisabled || isCreatingJob}
                            onClick={this.handleCreateJob}
                            size="md"
                          >
                            {this.messages.startNow}
                          </Button>
                        </li>
                      ) : null}
                      {showTemplateDropDown ? (
                        <li className="job-overview-item__dropdown">
                          <JobTemplatesDropdown
                            templates={templates}
                            value={this.state.templateId}
                            onChange={this.handleOnChange}
                            messages={this.messages}
                          />
                        </li>
                      ) : null}
                    </ul>
                  </fieldset>
                </li>
                <li
                  className={classNames('job-overview-item', {
                    disabled: isBlockDisabled,
                  })}
                >
                  <fieldset className="job-overview-item__fieldset">
                    <legend
                      className="job-overview-item__legend"
                      id="how-rate-candidates"
                    >
                      <p className="job-overview-item__number">02</p>
                      <h2 className="job-overview-item__title">
                        {this.messages.howRateCandidates}
                      </h2>
                    </legend>
                    <Button
                      aria-labelledby="how-rate-candidates how-rate-candidates-description"
                      className="job-overview-item__button"
                      disabled={isBlockDisabled}
                      onClick={() => {
                        this.props.history.push(
                          `/companies/jobs/edit/${id}/steps`,
                        );
                      }}
                      size="md"
                    >
                      {this.messages.startNow}
                    </Button>
                  </fieldset>
                </li>
                <li
                  className={classNames('job-overview-item', {
                    disabled: isBlockDisabled,
                  })}
                >
                  <fieldset className="job-overview-item__fieldset">
                    <legend
                      className="job-overview-item__legend"
                      id="who-should-analyze-candidates"
                    >
                      <p className="job-overview-item__number">03</p>
                      <h2 className="job-overview-item__title">
                        {this.messages.whoShouldAnalyzeCandidates}
                      </h2>
                    </legend>
                    <p
                      className="job-overview-item__description"
                      id="who-should-analyze-candidates-description"
                    >
                      {this.messages.whoApproveDescription}
                    </p>
                    <Button
                      aria-labelledby="who-should-analyze-candidates who-should-analyze-candidates-description"
                      className="job-overview-item__button"
                      disabled={isBlockDisabled}
                      onClick={() => {
                        this.props.history.push(
                          `/companies/jobs/edit/${id}/classifiers`,
                        );
                      }}
                      size="md"
                    >
                      {this.messages.startNow}
                    </Button>
                  </fieldset>
                </li>
                <li
                  className={classNames(
                    'job-overview-item job-overview-item--last',
                    {
                      disabled: isBlockDisabled,
                    },
                  )}
                >
                  <fieldset className="job-overview-item__fieldset">
                    <legend
                      className="job-overview-item__legend"
                      id="how-do-want-publish"
                    >
                      <p className="job-overview-item__number">04</p>
                      <h2 className="job-overview-item__title">
                        {this.messages.howDoWantPublish}
                      </h2>
                    </legend>
                    <p
                      className="job-overview-item__description"
                      id="how-do-want-publish-description"
                    >
                      {this.messages.howDoWantPublishDescription}
                    </p>
                    <Button
                      className="job-overview-item__button"
                      aria-labelledby="how-do-want-publish how-do-want-publish-description"
                      disabled={isBlockDisabled}
                      onClick={() => {
                        this.props.history.push(
                          `/companies/jobs/edit/${id}/pictures`,
                        );
                      }}
                      size="md"
                    >
                      {this.messages.startNow}
                    </Button>
                  </fieldset>
                </li>
              </ul>
            </Fragment>
          )}
        </GridContent>
      </JobContainer>
    );
  }
}

JobOverviewContainer.propTypes = propTypes;

const mapStateToProps = state => ({
  Job: state.reducers.Job,
  JobOverview: state.reducers.JobOverview,
});

export default injectIntl(
  withRouter(
    connect(mapStateToProps, {
      initJobOverview,
      createJobFromTemplate,
      getJob,
      clearSecondaryMenuForm,
    })(JobOverviewContainer),
  ),
);
