import { asyncActionType, createActionTypes } from '@gupy/front-commons';

export default createActionTypes(
  'JobApplication',
  asyncActionType('INIT'),
  asyncActionType('INIT_FILTERS'),
  asyncActionType('INIT_REPORT'),
  asyncActionType('INIT_CANDIDATE'),
  asyncActionType('GET_PROFILE_PAGINATION'),
  asyncActionType('INIT_CANDIDATE_CURRICULUM'),
  asyncActionType('INIT_DISAPPROVAL_REASONS'),
  asyncActionType('PATCH_APPLICATION'),
  asyncActionType('RELOAD'),
  asyncActionType('RELOAD_APPLICATIONS'),
  asyncActionType('RELOAD_FILTERS'),
  asyncActionType('SEND_EMAIL_TO_APPLICATIONS'),
  asyncActionType('SEND_EMAIL_TO_SINGLE_APPLICATION'),
  asyncActionType('SEND_MESSAGE_READ_RECEIPT'),
  asyncActionType('GET_SINGLE_APPLICANT_EMAILS'),
  asyncActionType('CHANGE_APPLICATION_FAVORITE'),
  asyncActionType('CHANGE_APPLICATION_CANDIDATE_FAVORITE'),
  asyncActionType('CHANGE_APPLICATION_LIKE'),
  asyncActionType('ADD_TAG'),
  asyncActionType('DELETE_TAG'),
  asyncActionType('CHANGE_CRITERIA_RATING'),
  asyncActionType('POST_COMMENT'),
  asyncActionType('POST_ATTACHMENT'),
  asyncActionType('CURRICULUM_DISQUALIFY_APPLICATION'),
  asyncActionType('CURRICULUM_CHANGE_APPLICATION_STEP'),
  asyncActionType('APPLICATION_UNDO_REPROVE'),
  asyncActionType('CURRICULUM_UNDO_REPROVE'),
  asyncActionType('SAVE_APPLICATIONS_FILTERS'),
  asyncActionType('REMOVE_APPLICATIONS_FILTERS'),
  asyncActionType('CLEAR_ALL_FILTERS'),
  asyncActionType('GET_AVAILABLE_FILTERS'),
  asyncActionType('SUBMIT_TAG_MODAL'),
  asyncActionType('GET_ALL_APPLICATIONS_WITH_NO_FEEDBACK'),
  asyncActionType('GET_FINAL_APPLICATIONS_COUNT'),
  asyncActionType('GET_EMAILS_MESSAGES'),
  asyncActionType('PATCH_THREAD_ALLOW_REPLY'),
  asyncActionType('GET_COMPANY_TAGS'),
  asyncActionType('GET_APPLICATION_EMAIL_MESSAGES'),
  asyncActionType('GET_APPLICATION_HISTORY'),
  asyncActionType('GET_PRE_HIRE_INFO'),
  asyncActionType('SAVE_CLICK_WHATSAPP_LINK'),
  asyncActionType('CHANGE_FORM_ADDRESS_SUGGESTIONS'),
  asyncActionType('GET_TIMELINE'),
  asyncActionType('UPDATE_USER_COMMENT'),
  asyncActionType('MOVE'),
  asyncActionType('DISQUALIFY'),
  asyncActionType('BULK_MOVE'),
  asyncActionType('BULK_DISQUALIFY'),
  asyncActionType('SEND_WHATSAPP_BUSINESS_TO_APPLICATIONS'),
  asyncActionType('GET_WHATSAPP_BUSINESS_AVAILABLE'),
  asyncActionType('GET_CANDIDATE_DOCUMENTS'),
  asyncActionType('GET_CANDIDATE_DOCUMENT_SIGNED_URL'),
  asyncActionType('LOAD_SELECTED_TEMPLATE'),
  asyncActionType('GET_FILTERS'),
  asyncActionType('GET_OFFERS'),
  asyncActionType('GET_ENGAGE_SURVEYS'),
  asyncActionType('GET_CONTENT_MESSAGE'),
  'SELECT_ADDRESS_SUGGESTION',
  'CHANGE_FORM_ADDRESS_SELECT_SUGGESTION',
  'CHANGE_FORM_ADDRESS_CLEAR_SUGGESTIONS',
  'CHANGE_FORM_FILTER',
  'CHANGE_HEADER',
  'CHANGE_APPLICATION',
  'STEP_CHANGED',
  'PAGE_CHANGED',
  'CHANGE_CRITERIAS_FILTER',
  'CHANGE_ORDER',
  'OPEN_SEND_EMAIL_MODAL',
  'CLOSE_SEND_EMAIL_MODAL',
  'CHANGE_EMAIL_FORM_MODEL',
  'CLEAR_EMAIL_FORM_MODEL',
  'CHANGE_EMAIL_TEMPLATE',
  'OPEN_DISAPPROVE_JOB_MODAL',
  'CLOSE_DISAPPROVE_JOB_MODAL',
  'CHANGE_FORM_TAG',
  'CHANGE_CURRICULUM_DATA_TAB',
  'CHANGE_FORM_COMMENT',
  'CHANGE_FORM_TAG_SUGGESTIONS',
  'CHANGE_FORM_TAG_CLEAR_SUGGESTIONS',
  'CHANGE_FORM_TAG_MODAL',
  'OPEN_TAG_MODAL',
  'CLOSE_TAG_MODAL',
  'TAG_MODAL_ADD_TAG',
  'TAG_MODAL_DELETE_TAG',
  'OPEN_MOVE_APPLICATION_MODAL',
  'CLOSE_MOVE_APPLICATION_MODAL',
  'TAB_MESSAGE_IS_LOADING',
  'TAB_MESSAGE_IS_LOADED',
  'CLOSE_MOVE_APPLICATION_MODAL',
  'OPEN_FILTER_MENU',
  'CLOSE_FILTER_MENU',
  'HIRING_FEEDBACK_REQUESTED',
  'OPEN_SEND_WHATSAPP_BUSINESS_MODAL',
  'CLOSE_SEND_WHATSAPP_BUSINESS_MODAL',
);
