import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

export const response = {
  responseAboutJobPostingTime: (
    <div>
      <p>
        <FormattedHTMLMessage
          id="copilot_insights_question_response_first"
          defaultMessage={`O <b>tempo de publicaçao de vaga</b> se refere ao tempo médio entre a
        última data de publicação da vaga e sua criação.
        <br />
        <br />

      Agora, escolha o período:`}
        />
      </p>
    </div>
  ),

  responseFirstApplication: (
    <div>
      <p>
        <FormattedHTMLMessage
          id="copilot_insights_question_response_second"
          defaultMessage={`O <b>tempo até a primeira candidatura</b> se refere ao tempo médio
                        entre a publicação da vaga e a inscrição da pessoa candidata contratada.
                        O cálculo é feito nas vagas em que há uma contratação.
                        <br/><br/>
                        Agora, escolha o período:`}
        />
      </p>
    </div>
  ),

  responseHiringTimeVacancy: (
    <div>
      <p>
        <FormattedHTMLMessage
          id="copilot_insights_question_response_third"
          defaultMessage={`O <b>tempo de contratação</b> se refere ao tempo médio entre a inscrição e a movimentação das pessoas candidatas para a etapa Contratação. Agora, escolha o período.<br/><br/>
                        `}
        />
      </p>
    </div>
  ),

  responseClosingTimeVacancy: (
    <div>
      <p>
        <FormattedHTMLMessage
          id="copilot_insights_question_response_fourth"
          defaultMessage={`O <b>tempo de fechamento de vaga</b> se refere ao tempo médio entre a
                        aprovação de uma vaga até a contratação da pessoa candidata aprovada pela empresa .<br/><br/>
                        Agora, escolha o período:`}
        />
      </p>
    </div>
  ),
  obterMensagem(chave) {
    return this[chave] || <p>Mensagem não encontrada.</p>;
  },
};
