import { Spinner } from '@gupy/design-system';
import { useFlagsStatus } from '@unleash/proxy-client-react';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Error500 from './500';

const propTypes = {
  children: PropTypes.node.isRequired,
};

const UNLEASH_LOAD_TIMEOUT_IN_MILLI_SECONDS = 5000;

function GlobalErrorHandling({ children }) {
  const [hasGlobalError, setGlobalError] = useState(false);

  const { flagsReady, flagsError } = useFlagsStatus();

  useEffect(() => {
    let timeoutId;

    if (!flagsReady) {
      timeoutId = setTimeout(() => {
        setGlobalError(true);
      }, UNLEASH_LOAD_TIMEOUT_IN_MILLI_SECONDS);
    }

    return () => clearTimeout(timeoutId);
  }, [flagsReady]);

  useEffect(() => {
    if (flagsError) {
      setGlobalError(true);
    }
  }, [flagsError]);

  if (!flagsReady && !hasGlobalError) {
    return (
      <div className="companies-routes-spinner">
        <Spinner size={60} />
      </div>
    );
  }

  if (hasGlobalError) {
    return <Error500 />;
  }

  return children;
}

GlobalErrorHandling.propTypes = propTypes;

export default GlobalErrorHandling;
