import { useCallback, useEffect, useMemo, useState } from 'react';

/**
 * @typedef {Object} UseLocalStorageResult
 * @property {string|null} storedValue Valor cacheado armazenado no local storage para a chave de inicialização usada na chamada inicial do hook
 * @property {Function} setValue Função que salva valor no local storage para a chave de inicialização usada na chamada inicial do hook
 * @property {Function} getStoredValue Função que busca valor no local storage para a chave de inicialização usada na chamada inicial do hook.
 *
 *    OBS: Use essa função apenas se não quiser usar o cache.
 */

/**
 * Returns a hook that allows you to store and retrieve values in local storage.
 *
 * @param {string} key - The key to use for storing the value in local storage.
 * @returns {UseLocalStorageResult} An object with two properties: `storedValue` and `setValue`.
 */
export const useLocalStorage = key => {
  const [storedValue, setStoredValue] = useState(null);
  const localStorageService = useMemo(() => localStorage, []);

  const getStoredValue = useCallback(() => {
    if (typeof window === 'undefined') return null;
    return localStorageService.getItem(key);
  }, [localStorageService, key]);

  const setValue = value => {
    if (typeof window !== 'undefined') {
      localStorageService.setItem(key, value);
      setStoredValue(value);
    }
  };

  useEffect(() => {
    setStoredValue(getStoredValue());
  }, [getStoredValue]);

  return {
    storedValue,
    getStoredValue,
    setValue,
  };
};
