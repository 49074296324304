import LocalstorageConstants, {
  keysToBeKeptAfterSignout,
} from '../constants/LocalstorageConstants';

class LocalstorageManager {
  constructor(localStorage) {
    this.localStorage = localStorage;
  }

  setCompanyToken(token) {
    this.localStorage.setItem(LocalstorageConstants.companyToken, token);
  }

  getCompanyToken() {
    return this.localStorage.getItem(LocalstorageConstants.companyToken);
  }

  hasCompanyToken() {
    return !!this.getCompanyToken();
  }

  clearCompanyToken() {
    this.localStorage.removeItem(LocalstorageConstants.companyToken);
  }

  /**
   * Remove todas as chaves do local storage, mas filtra algumas chaves na lista de chaves para serem ignoradas após logout.
   *
   * @see {@link keysToBeKeptAfterSignout}
   */
  clearAllLocalstorage() {
    const allowlist = keysToBeKeptAfterSignout.map(key => ({
      key,
      value: this.localStorage.getItem(key),
    }));

    this.localStorage.clear();

    allowlist.forEach(item => localStorage.setItem(item.key, item.value));
  }

  clearRSCIframeCloseFlag() {
    this.localStorage.removeItem(
      LocalstorageConstants.rscLinkedinPopupClosedSuccess,
    );
  }

  setRscLinkedinPopupClosedSuccess(isSuccess) {
    this.localStorage.setItem(
      LocalstorageConstants.rscLinkedinPopupClosedSuccess,
      isSuccess,
    );
  }
}

export default LocalstorageManager;
